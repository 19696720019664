import React from "react";

function About() {
  return (
    <p className="about">
      I am Student Developer at <a href ="https://summerofcode.withgoogle.com/programs/2022/projects/L28fukIz">Google Summer of Code'22 @Fortran-lang </a> and  passionate about development, problem solving and machine learning. 
    </p>
  );
}

export default About;
